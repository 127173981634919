<template>
  <div class="box p-3 box-min box-types-votes">
    <div class="good img-icon">
      <img src="https://app.tallos.com.br/assets/img/bom.svg" alt="bom">
      <span class="percentageGood font-weight-bold">{{percentageGood}}%</span>
    </div>
    <span class="font-weight-bold span-promotor absolute" >Promotor</span>
    <div class="bad img-icon">
      <img src="https://app.tallos.com.br/assets/img/ruim.svg" alt="ruim">
      <span class="percentage font-weight-bold">{{percentageBad}}%</span>
    </div>
    <span class="font-weight-bold span-detrator absolute" >Detrator</span>
    <div class="ok img-icon">
      <img src="https://app.tallos.com.br/assets/img/ok.svg" alt="ok">
      <span class="percentage font-weight-bold">{{percentageOk}}%</span>
    </div>
    <span class="font-weight-bold span-ok absolute" >Neutro</span>
  </div>
</template>

<script>
export default {
  props: {
    votes: {
      type: Array,
    }
  },
  data(){
    return {
      arrVotesGood: [],
      arrVotesBad: [],
      arrVotesOk: [],
      total: 0    
    }
  },
  mounted() {
    this.votes.forEach(vote => {
      if(vote.vote >= 9 && !vote.ignored) this.$set(this.arrVotesGood, this.arrVotesGood.length, vote.vote)
      if(vote.vote <= 6 && !vote.ignored) this.$set(this.arrVotesBad, this.arrVotesBad.length, vote.vote)
      if(vote.vote == 7 || vote.vote == 8 && !vote.ignored) this.$set(this.arrVotesOk, this.arrVotesOk.length, vote.vote)
      if(!vote.ignored) {
        this.total++
      }
    });
  },
  computed: {
    percentageGood() {
      const percentageGood = ((this.arrVotesGood.length) * 100) / this.total
      return Math.floor(percentageGood) || 0 
    },
    percentageBad() {
      const percentageBad = ((this.arrVotesBad.length) * 100) / this.total
      return Math.floor(percentageBad) || 0
    },
    percentageOk() {
      const percentageOk = ((this.arrVotesOk.length) * 100) / this.total
      return Math.floor(percentageOk) || 0
    },
  }
}
</script>

<style scoped>
.box {
  width: 330px;
  background-color: #e6e7e9;
  border-radius: 20px;
  height: 100%;
  min-height: 330px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.box-types-votes {
  max-width: 400px;
  max-height: 338px;
}

.img-icon img{
  width: 100%;
  max-width: 70px;
}

.img-icon {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 100px;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.good {
  background-color: #c1e3d1;
  max-width: 120px;
  min-height: 120px;
  top: 10px;
}
.bad {
  background-color: #f1b9c0;
  align-self: center;
  top: -10px;
  right: -20px;
}

.bad img {
  max-width: 60px;
}

.ok img {
  max-width: 55px;
}
.ok {
  background-color: #f0dbb4;
  align-self: end;
  top: -15px;
  right: 0;
  max-width: 90px;  
  min-height: 90px;
}
.percentage {
  position: absolute;
  bottom: 8px;
  right: 18px;
  font-size: 1em;
  color: #57585a;
}
.percentageGood {
  position: absolute;
  bottom: 15px;
  right: 25px;
  font-size: 1em;
  color: #57585a;
}
.absolute{
  position: absolute;
}

.span-promotor {
  top: 42%;
  left: 13%;
}
.span-detrator {
  top: 66%;
  left: 47.5%;
}
.span-ok {
  bottom: 2%;
  right: 10%;
}
</style>